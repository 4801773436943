import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyAnuhS0ZbQ7zc8nBqCA00p_UmXxxVLJHrk",
  authDomain: "noticias-171ac.firebaseapp.com",
  projectId: "noticias-171ac",
  storageBucket: "noticias-171ac.appspot.com",
  messagingSenderId: "1080428318853",
  appId: "1:1080428318853:web:23ad003fc13b39a7b519ec",
  measurementId: "G-VP6NPES691"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
